const ratings = require('../__generated__/appStoreRatings.json');

type AppStoreRating = {
  rating: number;
  appStoreUrl: string;
};

const useAppStoreRatings = (): AppStoreRating => {
  return ratings;
};

export default useAppStoreRatings;
