import Icon, { IconName } from '@components/Icon/Icon';
import classNames from 'classnames';
import React from 'react';
import { ContentfulText } from '../../../graphql-types';
import { Theme } from '../../styles/theme';
import useIsMobile from '../../hooks/useIsMobile.hook';

import * as styles from './UspList.module.scss';

export type UspListItem =
  | ContentfulText
  | {
      text: string | JSX.Element;
      icon?: IconName;
    };

type UspListProps = React.HTMLProps<HTMLUListElement> & {
  items: UspListItem[];
  showVerticalListMobile?: boolean;
  theme?: Theme | 'purple';
};

function UspList({ items, showVerticalListMobile, theme, ...rest }: UspListProps) {
  const classes = classNames({
    [styles.uspList]: true,
    [styles.darkTheme]: theme === 'dark',
    [styles.purpleTheme]: theme === 'purple',
    [styles.blackTheme]: theme === 'black',
  });

  const isMobile = useIsMobile();

  const content = (isMobile && !showVerticalListMobile) ? (
    <div className={styles.mobileContainer}>
      {items.map((l, i) => (
        <div key={i}>
          <div className={styles.icon}>
            <Icon name={l.icon as IconName} />
          </div>
          <p className={styles.mobileUspBody}>{l.text}</p>
        </div>
      ))}
    </div>
  ) : (
    <ul className={classes} {...rest}>
      {items.map((l, i) => (
        <li key={i}>
          {l.icon && (
            <div
              style={{
                width: 32,
                marginRight: 4,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon name={l.icon as IconName} />
            </div>
          )}
          <p>{l.text}</p>
        </li>
      ))}
    </ul>
  );

  return <div>{content}</div>;
}

export default UspList;
