import classNames from 'classnames';
import React from 'react';

import * as styles from './Grid.module.scss';

type GridProps = React.HTMLProps<HTMLDivElement> & {
  spacedRows?: boolean;
};

function Grid({ spacedRows, children, ...rest }: GridProps) {
  const classes = classNames({
    [styles.grid]: true,
    [styles.spacedRows]: spacedRows,
  });

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
}

export function Row({ children }) {
  return <div className={styles.row}>{children}</div>;
}

export default Grid;
