import React, { useState } from 'react';
import { graphql, navigate } from 'gatsby';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';

import SubNavPills from '@components/SubNavPills/SubNavPills';
import Container from '@components/Container';
import Grid, { Row } from '@components/Grid';
import TextHero from '@components/TextHero';
import Card, { CardImage } from '@components/Card/Card';
import lanPages from '@src/data/lanPages';
import {
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulQuestion,
  PrivatlanQuery,
} from '../../graphql-types';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import LoanBrokerApplicationEntry from '@src/components/LoanBrokerApplication/LoanBrokerApplicationEntry';
import * as styles from './lan.module.scss';
import Testimonial1 from '../images/lan/Testimonial1.png';
import Testimonial2 from '../images/lan/Testimonial2.png';
import Testimonial3 from '../images/lan/Testimonial3.png';
import Testimonial4 from '../images/lan/Testimonial4.png';
import Testimonial5 from '../images/lan/Testimonial5.png';
import FreeToApplySvg from '../icons/2.0/illustrations/FreeToApply.svg';
import Markdown from '@src/components/markdown';
import Badge from '@components/Badge/Badge';
import { AppDownloadUrl } from '@src/pageConstants';
import useAppStoreRatings from '@src/hooks/useAppStoreRatings';
import '@src/sentry'; // Enable Sentry monitoring for errors
import WideCardWithCarousel from '@src/components/WideCardWithCarousel';
import SwipeableCard from '@src/components/SwipeableCard';
import PhoneSvg from '../icons/2.0/illustrations/Phone.svg';
import { useMediaQuery } from 'react-responsive';
import { isAndroid } from 'react-device-detect';
import { isMobile, playStoreRating, playStoreUrl } from '@src/lib/utils';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import useSearchParam from '@src/hooks/useSearchParam.hook';
import useDialogRoute from '@src/components/Dialog/useDialogRoute.hook';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';

type LanProps = {
  data: PrivatlanQuery;
};

const Lan = ({ data }: LanProps) => {
  const testimonialImages = [
    Testimonial1,
    Testimonial2,
    Testimonial3,
    Testimonial4,
    Testimonial5,
  ];

  const adtractionId = useSearchParam('at_gd');
  const [showGuide, setShowGuide] = useState(false);
  const { rating, appStoreUrl } = useAppStoreRatings();

  if (adtractionId) {
    localStorage.setItem('at_gd', adtractionId);
  }

  const adwordsId = useSearchParam('gclid');
  if (adwordsId) {
    localStorage.setItem('gclid', adwordsId);
  }

  const {
    headLine,
    pageHeaderDescription: { pageHeaderDescription },
    questions,
    questionsHeading,
    pageContent,
  } = data.contentfulPage;

  const jamforDialogData = pageContent[2] as ContentfulDialogContent;
  const jamforDialog = useDialogRoute('prisgaranti');

  const openLoanCard = pageContent[3] as ContentfulCard;
  const openLoanImage = useContentfulImage(openLoanCard.backgroundImage);

  const guaranteeCard = pageContent[6] as ContentfulCard;
  const negotiateCard = pageContent[7] as ContentfulCard;
  const downloadAppCard = pageContent[8] as ContentfulCard;
  const loanBrokersCard = pageContent[9] as ContentfulCard;
  const loanPredictionCard = pageContent[11] as ContentfulCard;
  const loanBrokersDialog = useDialogRoute('langivare');
  const loanBrokerDialogContent = pageContent[10] as ContentfulDialogContent;

  const storeScore = isAndroid ? playStoreRating : rating.toFixed(1);
  const storeUrl = isAndroid ? playStoreUrl : appStoreUrl;

  const isSmallScreen = useMediaQuery({ query: '(max-width: 896px)' });

  return (
    <Layout
      path="/lan"
      theme="soft"
      questions={questions as ContentfulQuestion[]}
      questionsHeading={questionsHeading}
    >
      <SEO title={headLine} description={pageHeaderDescription} />
      {!isSmallScreen && <SubNavPills theme="soft" links={lanPages} />}
      <div className={styles.wrapper}>
        <Container className={styles.lanWrapper}>
          <Grid>
            <div className={styles.loanFormContainer}>
              <TextHero
                className={styles.textHero}
                leftAligned
                size="large"
                title="Låna upp till 600 000 kr"
                subtitle={
                  isSmallScreen
                    ? `
- 1 UC
- Jämför erbjudanden
- Kostnadsfritt
`
                    : `
- Svar från flera banker med endast en UC
- Jämför och välj erbjudande i appen - just nu ingår 1 månad Rocker Premium`
                }
                RightIcon={() => (
                  <FreeToApplySvg className={styles.freeToApply} />
                )}
              />
              {
                <a
                  href={storeUrl}
                  className={styles.appStoreRating}
                  target="_blank"
                  rel="noreferrer"
                >
                  {[...Array(5)].map((x, i) => (
                    <RatingStar
                      ratio={
                        i + 1 <= storeScore
                          ? 1
                          : i + 1 - storeScore > 1
                          ? 0
                          : storeScore - i
                      }
                      id={i}
                      key={i}
                    />
                  ))}
                  <div>{storeScore.toString().replace('.', ',')}</div>
                  <div>i {isAndroid ? 'play' : 'app'} store</div>
                </a>
              }
            </div>
            <LoanBrokerApplicationEntry
              isGuideShown={showGuide}
              onOpenGuide={() => setShowGuide(true)}
              onCloseGuide={() => setShowGuide(false)}
            />

            <Row>
              <Markdown className={styles.exampleSection}>
                {`Representativt exempel: 100 000 kr i annuitetslån med 10 års löptid, nominell ränta 6,95 % och 0 kr i uppläggnings-/aviavgift blir den effektiva räntan 7,18 %. Totalt belopp att betala: 139 020 kr. Månadskostnad: 1 159 kr fördelat på 120 betalningstillfällen. Rocker AB är kreditförmedlare och din ansökan kommer att skickas till de kreditgivare som bäst matchar din profil. Återbetalningstid 1-20 år. Räntan är rörlig och sätts individuellt. Räntespann mellan: 5,20 % - 27,95 % (Uppdaterat november 2023).`}
              </Markdown>
            </Row>
          </Grid>
        </Container>
        <div className={styles.cardsWrapper}>
          <Container>
            <Grid>
              {/* Loan Prediction */}
              <Row>
                <Card
                  className={styles.loanPredictionCard}
                  title={loanPredictionCard.title}
                  text={loanPredictionCard.subtitle.replaceAll('\\n', '\n')}
                  contentfulImg={useContentfulImage(loanPredictionCard.backgroundImage)}
                  cta={loanPredictionCard.linkText}
                  ctaHref={loanPredictionCard.linkPath}
                  onClick={() => navigate(loanPredictionCard.linkPath)}
                  theme="soft-2"
                  snap={true}
                  wide={!isSmallScreen}
                ></Card>
              </Row>
              <Card
                title={negotiateCard.title}
                text={negotiateCard.subtitle}
                cta={negotiateCard.linkText}
                onClick={loanBrokersDialog.openDialog}
                theme="soft"
              >
                <CardImage
                  imageData={useContentfulImage(negotiateCard.backgroundImage)}
                />
              </Card>
              <Card theme="dark-purple" centerContent>
                <div className={styles.callCard}>
                  <PhoneSvg className={styles.phoneSvg} />
                  <h3 style={{ textAlign: 'center', fontSize: '36px' }}>
                    Vill du hellre bli guidad?
                  </h3>
                  <p style={{ textAlign: 'center' }}>
                    Lån kan vara en djungel. Men vår kundtjänst kan hjälpa dig
                    fram till din bästa ansökan.
                  </p>
                  <div
                    style={{
                      color: '#FCD4F3',
                      textAlign: 'center',
                      marginTop: '16px',
                    }}
                  >
                    <PrimaryButton
                      color="purple"
                      href="https://calendly.com/d/39q-kvg-t3w/telefonsamtal-rocker"
                    >
                      Ring mig
                    </PrimaryButton>
                  </div>
                </div>
              </Card>

              <SwipeableCard
                theme="dark-purple"
                badge={<Badge color="red">Hur det går till!</Badge>}
                cardItems={[
                  {
                    title: 'Gör en ansökan',
                    text:
                      'Fyll i en digital ansökan. Det tar inte längre än någon minut och det tas aldrig mer än en kreditupplysning',
                    imgPath: <div className={styles.applyStepsBadge}>1</div>,
                  },
                  {
                    title: 'Ladda ner appen och ta emot anbud',
                    text:
                      'Luta dig tillbaka i soffan och vänta på att anbuden från våra partners strömmar in i Rocker-appen.',
                    imgPath: <div className={styles.applyStepsBadge}>2</div>,
                  },
                  {
                    title: 'Jämför och välj ditt bästa bud',
                    text:
                      'Vissa erbjuder högre lånebelopp, andra lägre ränta eller kanske längre löptid. Hitta det som känns rätt för dig. Och du, tveka inte att ringa oss ifall du behöver hjälp.',
                    imgPath: <div className={styles.applyStepsBadge}>3</div>,
                  },
                ]}
              ></SwipeableCard>
              <Card
                title={downloadAppCard.title}
                text={downloadAppCard.subtitle}
                cta={downloadAppCard.linkText}
                ctaHref={AppDownloadUrl}
                theme="soft"
                badge={<Badge color="pink">Just nu!</Badge>}
              >
                <CardImage
                  imageData={useContentfulImage(
                    downloadAppCard.backgroundImage,
                  )}
                />
              </Card>
              <Row>
                <WideCardWithCarousel
                  title={loanBrokersCard.title}
                  text={loanBrokersCard.subtitle}
                  cta={loanBrokersCard.linkText}
                  ctaHref={loanBrokersCard.linkPath}
                  theme="soft-2"
                  carouselImages={testimonialImages}
                />
              </Row>
            </Grid>
          </Container>
          <ContentfulDialog
            contentfulData={jamforDialogData}
            visible={jamforDialog.isOpen}
            onClose={jamforDialog.closeDialog}
          />
          <ContentfulDialog
            contentfulData={loanBrokerDialogContent}
            visible={loanBrokersDialog.isOpen}
            onClose={loanBrokersDialog.closeDialog}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Lan;

export const query = graphql`
  query Privatlan {
    contentfulPage(pageUrl: { eq: "/lan" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
        childMarkdownRemark {
          html
        }
      }
      pageContactHeader
      questionsHeading
      questions {
        answer {
          answer
        }
        question
      }
      pageContent {
        ... on ContentfulCard {
          additionalLinkPath
          additionalLinkText
          linkPath
          linkText
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
        }
        ... on ContentfulCardsSectionComponent {
          entryTitle
          card {
            additionalLinkPath
            additionalLinkText
            subtitle
            title
            linkText
            linkPath
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          heading
          textContent {
            textContent
          }
          ctaText
          ctaHref
          extraContent {
            ... on ContentfulTextList {
              advancedTextListItems {
                text
                icon
              }
              listName
            }
          }
          questions {
            answer {
              answer
            }
            question
          }
        }
      }
    }
  }
`;

const RatingStar = ({ ratio, id }: { ratio: number; id: number }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id={`ratingStarRatio${id}`}>
        <stop offset="0%" stopColor="#8940F7" />
        <stop offset={`${Math.round(ratio * 100)}%`} stopColor="#8940F7" />
        <stop
          offset={`${Math.round(ratio * 100)}%`}
          stopColor="rgba(0,0,0,0)"
        />
        <stop offset="100%" stopColor="rgba(0,0,0,0)" />
      </linearGradient>
    </defs>
    <path
      d="M8 0.867004L10.318 5.56301L15.5 6.316L11.75 9.97101L12.635 15.133L8 12.696L3.365 15.133L4.25 9.97101L0.5 6.316L5.682 5.56301L8 0.867004Z"
      stroke="#8940F7"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={`url(#ratingStarRatio${id})`}
    />
  </svg>
);
