// extracted by mini-css-extract-plugin
export var backdropContent = "WideCardWithCarousel-module--backdropContent--c680f";
export var badge = "WideCardWithCarousel-module--badge--3643e";
export var black = "WideCardWithCarousel-module--black--3ad5a";
export var card = "WideCardWithCarousel-module--card--e5ed6";
export var cardContent = "WideCardWithCarousel-module--cardContent--af694";
export var cardSnapContainer = "WideCardWithCarousel-module--cardSnapContainer--700ed";
export var carousel = "WideCardWithCarousel-module--carousel--3b975";
export var clickable = "WideCardWithCarousel-module--clickable--23ba2";
export var ctaButton = "WideCardWithCarousel-module--ctaButton--935f3";
export var dark = "WideCardWithCarousel-module--dark--d64d8";
export var darkPurple = "WideCardWithCarousel-module--darkPurple--f6481";
export var purple = "WideCardWithCarousel-module--purple--14fca";
export var soft = "WideCardWithCarousel-module--soft--e7422";
export var softTwo = "WideCardWithCarousel-module--softTwo--75989";
export var text = "WideCardWithCarousel-module--text--9e628";
export var textContent = "WideCardWithCarousel-module--textContent--a39db";
export var title = "WideCardWithCarousel-module--title--b5a8c";