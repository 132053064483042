import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import apiSlice from '../../redux/api';
import loanApplicationSlice from '../../redux/loanApplication';

import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import { LoanApplicationReduxType } from '@src/redux/loanApplication';
import AnimateHeight from 'react-animate-height';
import BankIdIcon from '../../icons/2.0/BankId.svg';
import PadlockIcon from '../../icons/2.0/Padlock.svg';
import { getMonthlyCost } from '../../lib/monthlyCost';
import { exampleInterestRate, formatMoney } from '../../lib/utils';
import Card from '../Card';
import NewSliderInput from '../forms/NewSliderInput';
import PlusMinusNumericInput from '../forms/PlusMinusNumericInput';
import YesNoInput from '../forms/YesNoInput';
import Markdown from '../markdown';
import * as styles from './LoanBrokerApplicationEntry.module.scss';
import LoanBrokerApplicationFlow from './LoanBrokerApplicationFlow';
import { useMediaQuery } from 'react-responsive';

const errorMessages = {
  ssn: 'Ange ett giltigt personnummer',
  phone: 'Ange ett giltigt telefonnummer',
  companyPhone: 'Ange ett giltigt telefonnummer',
  email: 'Ange en giltig epostadress',
  monthlySalary: `Ange din månadsinkomst (min 10 tkr)`,
  occupation: 'Ange en sysselsättning',
  accommodation: 'Ange en boendeform',
  numberOfChildren: 'Ange ett antal av barn',
  nameOfEmployer: 'Ange ett giltigt företagsnamn',
  laidOff: 'Fyll i fältet',
  maritalStatus: 'Måste anges',
  since: 'format måste vara MM/ÅÅÅÅ',
  to: 'format måste vara MM/ÅÅÅÅ',
  accommodationMonthlyCost: 'Får inte vara tomt',
};

function LoanBrokerApplicationEntry(props) {
  const isExpanded = true;
  const [showGuide, setShowGuide] = useState(false);

  const loanApplication: LoanApplicationReduxType = props.loanApplication;
  const isSmallScreen = useMediaQuery({ query: '(max-width: 896px)' });
  const isShortScreen = useMediaQuery({ query: '(max-height: 667px)' });

  const listener = _e => {
    if (isSmallScreen && isShortScreen && typeof window !== 'undefined') {
      window.zE('messenger:set', 'zIndex', window.scrollY < 100 ? -1 : 999998);
    }
  };

  useEffect(() => {
    if (isSmallScreen && isShortScreen && typeof window !== 'undefined') {
      listener(null);
      window.addEventListener('scroll', listener);
    }
    return () => {
      if (isSmallScreen && isShortScreen && typeof window !== 'undefined') {
        window.zE('messenger:set', 'zIndex', 999998);
        window.removeEventListener('scroll', listener);
      }
    };
  }, []);

  useEffect(() => {
    if (showGuide) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showGuide]);

  const minLoanAmount = 10_000;

  const closeModal = React.useCallback(() => {
    setShowGuide(false);
  }, [showGuide]);

  return (
    <>
      <Card
        className={styles.formWrapper}
        childrenOverflow="visible"
        freeSize={isExpanded}
      >
        {/* TODO: Should we remove the form */}
        <form id="loanForm" style={props.style}>
          <div className={styles.formCard}>
            <div>
              <NewSliderInput
                min={minLoanAmount}
                max={600_000}
                step={5_000}
                formatValue={formatMoney}
                label="Lånebelopp"
                ariaLabel="Lånebelopp"
                onChange={props.updateAppliedAmount}
                value={loanApplication.appliedAmount}
                showMinMaxLabels={true}
              />
              <PlusMinusNumericInput
                min={1}
                max={loanApplication.appliedAmount < 200_000 ? 15 : 20}
                ariaLabel="Antal år för återbetalning"
                readOnlyTextInput
                label={`Lånetid`}
                onChange={props.updateAmortizationPeriod}
                value={loanApplication.amortizationPeriod}
              />
            </div>
          </div>
          <div className={styles.collectLoanSection}>
            <div>
              <div>Har du lån att samla?</div>
              <YesNoInput
                onChange={(evt, value) => {
                  props.updateRenegotiateLoans(value);
                  if (
                    isSmallScreen &&
                    isShortScreen &&
                    value &&
                    window &&
                    window.scrollY < 100
                  ) {
                    window.scrollTo({
                      top: 100,
                      left: 0,
                      behavior: 'smooth',
                    });
                  }
                }}
                value={loanApplication.renegotiateLoans}
              />
            </div>
            <AnimateHeight
              height={
                loanApplication.renegotiateLoans ? 'auto' : 0
              }
            >
              <NewSliderInput
                hideSlider={true}
                min={minLoanAmount}
                max={loanApplication.appliedAmount}
                step={5_000}
                value={loanApplication.renegotiateLoansAmount}
                onChange={props.updateRenegotiateLoansAmount}
                formatValue={formatMoney}
                label={`Belopp att samla av ${formatMoney(
                  loanApplication.appliedAmount,
                )} kr`}
              />
            </AnimateHeight>
          </div>

          <div className={styles.exampleCostSection}>
            <div>Ungefärlig kostnad:</div>
            <div>
              <div>
                {formatMoney(
                  getMonthlyCost({
                    amount: loanApplication.appliedAmount,
                    terms: loanApplication.amortizationPeriod * 12,
                    interestRate: exampleInterestRate(
                      loanApplication.appliedAmount,
                    ),
                  }),
                )}
              </div>
              <div>kr/mån</div>
            </div>
          </div>
        </form>
        <PrimaryButton
          onClick={() => setShowGuide(true)}
          className={styles.submitButton}
        >
          Påbörja ansökan
        </PrimaryButton>
        <div className={styles.securedLoanApplicationSection}>
          <div>
            <PadlockIcon />
          </div>
          <div>Snabb och säker ansökan med BankID</div>
          <div>
            <BankIdIcon />
          </div>
        </div>
        <Markdown openLinksInNewTab className={styles.fineprintSection}>
          Genom att påbörja ansökan godkänner du
          [användarvillkoren](https://rocker.com/anvandarvillkor/) samt
          [Tilläggsvillkor till Rockers
          Användarvillkor](https://rocker.com/anvandarvillkor/lan/). För
          information om hur vi hanterar din data, se vår
          [personuppgiftspolicy](https://rocker.com/personuppgiftspolicy/).
        </Markdown>
      </Card>
      {showGuide &&
        <LoanBrokerApplicationFlow closeModal={closeModal} submitWithBankId={true} />
      }      
    </>
  );
}

const mapStateToProps = (state: {
  loanApplication: LoanApplicationReduxType;
}) => ({
  loanApplication: state.loanApplication,
});

const mapDispatchToProps = dispatch => {
  const { authStarted, authCompleted } = apiSlice.actions;
  return bindActionCreators(
    {
      ...loanApplicationSlice.actions,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoanBrokerApplicationEntry);

// export default LoanBrokerApplicationEntry;
