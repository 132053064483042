// extracted by mini-css-extract-plugin
export var appStoreRating = "lan-module--appStoreRating--3d0b5";
export var applyStepsBadge = "lan-module--applyStepsBadge--91e6a";
export var callCard = "lan-module--callCard--e718a";
export var cardsWrapper = "lan-module--cardsWrapper--651b1";
export var exampleSection = "lan-module--exampleSection--fc81a";
export var freeToApply = "lan-module--freeToApply--a5aee";
export var lanWrapper = "lan-module--lanWrapper--0fb5c";
export var loanFormContainer = "lan-module--loanFormContainer--d07b6";
export var loanPredictionCard = "lan-module--loanPredictionCard--6b1ed";
export var phoneSvg = "lan-module--phoneSvg--3b620";
export var textHero = "lan-module--textHero--1cc95";
export var wrapper = "lan-module--wrapper--85b72";