import React, { ReactChild } from 'react';
import classNames from 'classnames';

import * as styles from './WideCardWithCarousel.module.scss';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { Theme } from '@src/styles/theme';
import ReactMarkdown from 'react-markdown';
import useIsMobile from '../../hooks/useIsMobile.hook';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

export type WideCardWithCarouselProps = Omit<
  React.HTMLProps<HTMLDivElement>,
  'ref'
> & {
  title?: string;
  text?: string;
  theme?: Theme | 'purple' | 'soft-2' | 'dark-purple';
  cta?: string;
  ctaHref?: string;
  style?: React.CSSProperties;
  centerContent?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  snap?: boolean;
  badge?: ReactChild;
  carouselImages: Array<any>;
};

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function WideCardWithCarousel({
  title,
  text,
  theme = 'white',
  cta,
  ctaHref,
  style,
  className,
  onClick,
  snap,
  badge,
  carouselImages,
  ...restProps
}: WideCardWithCarouselProps) {
  const s = { ...style };

  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  const isMobile = useIsMobile();

  const classes = classNames({
    [className]: className,
    [styles.card]: true,
    [styles.purple]: theme === 'purple',
    [styles.soft]: theme === 'soft',
    [styles.dark]: theme === 'dark',
    [styles.black]: theme === 'black',
    [styles.softTwo]: theme === 'soft-2',
    [styles.darkPurple]: theme === 'dark-purple',
    [styles.clickable]: onClick,
    [styles.withCta]: cta,
    [styles.cardSnapContainer]: snap,
  });

  const buttonColor = theme === 'purple' ? 'dark' : 'white';

  const isClickable = !!(ctaHref || onClick);

  const extraProps = isClickable
    ? {
        asWrapper: true,
        href: ctaHref,
      }
    : {};

  const carousel = (
    <div className={styles.carousel}>
      <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {carouselImages.map((step, index) => (
            <div key={index}>
              <Box component="img" sx={{ width: '100%' }} src={step} />
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          variant="dots"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            bottom: '120px',
            backgroundColor: 'transparent',
            '& .MuiMobileStepper-dotActive': {
              backgroundColor: '#736E7A',
              borderRadius: '32px',
              width: '18px !important',
              height: '6px',
            },
            '& .MuiMobileStepper-dot': {
              backgroundColor: '#736E7A',
              height: '6px',
              width: '6px',
            },
          }}
          steps={carouselImages.length}
          position="static"
          activeStep={activeStep}
          nextButton={<></>}
          backButton={<></>}
        />
      </Box>
    </div>
  );

  return (
    <div
      className={classes}
      style={s}
      onClick={onClick}
      aria-label={cta}
      {...restProps}
      {...extraProps}
    >
      <div className={styles.cardContent}>
        {badge && <div className={styles.badge}>{badge}</div>}
        {(title || text) && (
          <div className={styles.textContent}>
            {title && (
              <ReactMarkdown className={styles.title}>
                {title.replaceAll('<br>', '  \n')}
              </ReactMarkdown>
            )}
            {text && (
              <ReactMarkdown className={styles.text}>
                {text.replaceAll('<br>', '  \n')}
              </ReactMarkdown>
            )}
          </div>
        )}
        {isMobile && carousel}
        {!isMobile && (
          <div className={styles.ctaButton}>
            <PrimaryButton theme={buttonColor} href={ctaHref} onClick={onClick}>
              {cta}
            </PrimaryButton>
          </div>
        )}
      </div>
      {!isMobile && carousel}
    </div>
  );
}

export default WideCardWithCarousel;
